import React, { useContext, useEffect, useState } from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import styles from './header.module.css'
import { UserContext } from './layout';

import MenuIcon from '@material-ui/icons/Menu';

export default function Navigation() {

  return (<StaticQuery<GatsbyTypes.NavigationQuery>
    query={graphql`
    query Navigation {
      contentfulHeaderItems {
        id
        pages {
          id
          slug
          title
        }
      }
    }
  `}
    render={(data: GatsbyTypes.NavigationQuery) => {
      let pages = data.contentfulHeaderItems?.pages?.slice();

      const userContext = useContext(UserContext)
      if(!userContext){
        return null;
      }
      const [navigationOpen, setNavigationOpen] = useState<boolean>(false)



      let extraPages = [
        {
          id: "Courses",
          title: "Courses",
          slug: "courses",
        },
        {
          id: "quote",
          title: "Request a Quote",
          slug: "quote",
        },
        {
          id: "cart",
          title: "Cart",
          slug: "cart",
        },
        {
          id: "account",
          title: userContext?.loggedIn ? "Account" : "Login",
          slug: "account",
        },
      ]
      pages?.push(...extraPages);
      if (userContext?.isMobile) {
        return (
          <div className={styles.navWrapper}>
            <a onClick={() => setNavigationOpen(!navigationOpen)} className={styles.mobileNavigationButton}> <MenuIcon></MenuIcon></a>
            {navigationOpen ? <Menu pages={pages}></Menu> : null}
          </div>
        )
      }
      else {
        return (
          <Menu pages={pages} />
        )
      }
    }} />)
}

type MenuProps = {
  pages: GatsbyTypes.ContentfulPage[]
}
function Menu({ pages }: MenuProps) {
  return (
    <nav role="navigation">
      <ul className={styles.navigation}>
        <li className={styles.navigationItem} key={"home"}>
          <Link to={`/`}>Home</Link>
        </li>

        {console.log(pages)}
        {pages.map(page => <li className={styles.navigationItem} key={page?.slug}>
          <Link to={`/${page?.slug}`}>{page?.title}</Link>
        </li>
        )}
      </ul>
    </nav>
  );
}

