import React, { useEffect, useState } from 'react'
import './base.css'
import Navigation from './navigation'
import Header from "./header"
import { Container } from "@material-ui/core"
import Footer from "./footer.tsx"

import firebase from 'firebase';
export const isBrowser = typeof window !== "undefined"


var firebaseConfig = {
  apiKey: "AIzaSyBXpy7OBMBvc9Xg4eAj3xLfnsamxO4abj8",
  authDomain: "leanqualityacademy.firebaseapp.com",
  projectId: "leanqualityacademy",
  storageBucket: "leanqualityacademy.appspot.com",
  messagingSenderId: "610113720926",
  appId: "1:610113720926:web:7c8df2a127abc499ea1acf"
};

if (isBrowser) {
  firebase.initializeApp(firebaseConfig);
}


export type UserContextType = {
  loggedIn: boolean,
  isMobile: boolean,
}

export const UserContext = React.createContext<UserContextType | null>(null);




export default function Layout({ children, shouldContainer }) {

  const [loggedIn, setLoggedIn] = useState<boolean>(null)
  const [isMobile, setIsMobile] = useState(false)


  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  // handleResize();

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setLoggedIn(!!user);
    });
    handleResize();
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);







  let userContext: UserContextType = {
    loggedIn: loggedIn,
    isMobile: isMobile,
  }

  if (shouldContainer) {
    return (
      <UserContext.Provider value={userContext}>

        <Header />
        <Container style={{
          overflow: "hidden",
          flexGrow: 1,
          flexShrink: 0,
          flexBasis: "auto"
        }} >

          {children}

        </Container>
        <Footer />

      </UserContext.Provider>
    )
  }

  else {
    return (
      <UserContext.Provider value={userContext}>
        <Header />
        <div style={{
          overflow: "hidden",
          flexGrow: 1,
          flexShrink: 0,
          flexBasis: "auto"
        }} >

          {children}

        </div>
        <Footer />
      </UserContext.Provider >
    )
  }

}

export const withLayout = (WrappedComponent: React.ComponentType) => {
  return class extends React.Component {
    render() {
      return (
        <Layout shouldContainer={true} >
          <WrappedComponent {...this.props}></WrappedComponent>
        </Layout>
      )
    }
  }
}


export const getFirebaseToken = async () => {
  try {
    return await firebase.auth().currentUser?.getIdToken(true);
  }
  catch (err) {
    alert("Something went wrong... Please contact us at help@leanqualityacademy.com if you continue experiencing this issue. Error:" + err.toString());
    location.reload();
  };
}
export const getUserDocument = async () =>{
  let userID = await firebase.auth().currentUser?.uid;
  let user = await (await firebase.firestore().collection("users").doc(userID).get()).data();
  return user;
}