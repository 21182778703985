import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import styles from './footer.module.css'
import { Button, ButtonGroup, Container } from '@material-ui/core'
// import Navigation from './navigation'
import { navigate } from '@reach/router';




export default function Footer() {

	return (
		<div className={styles.container}>

			<div className={styles.topContainer}>
				<Container>
					<h2 className={styles.aboutUsHeader}>About us</h2>
					<p className={styles.aboutText}>
						Lean Quality Academy has experts with over 18 years of experience providing management system workshop, quality engineering solutions and professional consultancy services in North America.
					</p>

					<ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="text primary button group">
						<Button onClick={() => {navigate("about")}} >More About Us</Button>
						{/* <Button onClick={() => {navigate("contact")}}>Contact Us</Button> */}
					</ButtonGroup>

				</Container>

			</div>

			<div className={styles.bottomContainer}>
				<Container>

					© 2021 LEAN QUALITY ACADEMY
					{/* <div className='developedBy'>Website Developed By:			<a target="_blank" rel="nofollow" href="http://Shahan.ca">Shahan Neda</a></div> */}
				</Container>

				{/* <div className="enigma_footer_social_div">
				<ul className="social">
					<li className="facebook" data-toggle="tooltip" data-placement="top" title="Facebook"><a href="https://www.facebook.com/LeanQualityAcademy/?fref=ts"><i className="fa fa-facebook"></i></a></li>
					<li className="twitter" data-toggle="tooltip" data-placement="top" title="Twitter"><a href="https://twitter.com/LeanQualityA"><i className="fa fa-twitter"></i></a></li>
					<li className="linkedin" data-toggle="tooltip" data-placement="top" title="Linkedin"><a href="https://www.linkedin.com/company/lean-quality-academy?trk=top_nav_home"><i className="fa fa-linkedin"></i></a></li>
					<li className="youtube" data-toggle="tooltip" data-placement="top" title="Youtube"><a href="https://www.youtube.com/channel/UCtvS3gNnP75gNHfc8CaeT7w"><i className="fa fa-youtube"></i></a></li>
					<li className="gplus" data-toggle="tooltip" data-placement="top" title="Google+"><a href="https://plus.google.com/104788124854594828728"><i className="fa fa-google-plus"></i></a></li>
				</ul>
			</div> */}

			</div >
		</div>
	)
}
