import React, { useContext } from 'react'
import { graphql, Link,  StaticQuery } from 'gatsby'
import styles from './header.module.css'
import Navigation from './navigation'


import { navigate } from '@reach/router';
import { UserContext } from './layout';

export default function Header() {

	return (
		<div className={styles.header}>
			<div className="header-top">
				<div className={styles.logo} onClick={() => {navigate("/")}}>
					<img src="/logo.png" ></img>
					<p> Lean Quality Academy</p>
				</div>
			</div>

			<Navigation />
		</div>
	)
}
